import { Observable, Subject, map } from 'rxjs';
import { IDataService } from './models/data-service/data-service.interface';
import { ConfigService } from '../services/config.service';
import { HttpClient } from '@angular/common/http';
import { IColumnDef } from './models/data-service/column-def.interface';
import { GridConfig } from './grid-config.class';
import { CustomDate } from './models/table-entities/custom-date.interface';
import { EventEmitter } from '@angular/core';

interface ApiResponse<EntityType> {
  status: number;
  data?: EntityType[] | EntityType | undefined;
}

export abstract class DataServiceBase<EntityType>
  implements IDataService<EntityType>
{
  private entryCreatedSubject = new Subject<EntityType>();
  private entryDeletedSubject = new Subject<EntityType>();
  private entryUpdatedSubject = new Subject<EntityType>();

  public entryCreated$ = this.entryCreatedSubject.asObservable();
  public entryUpdated$ = this.entryUpdatedSubject.asObservable();
  public entryDeleted$ = this.entryDeletedSubject.asObservable();

  constructor(public configService: ConfigService, public http: HttpClient) {}

  delete(id: number): Observable<boolean> {
    return this.http
      .delete<ApiResponse<EntityType>>(
        this.configService.getApiUrl() + this.getServiceEndpoint() + '/' + id
      )
      .pipe(
        map((response) => {
          if (response.status >= 200) {
            this.entryDeletedSubject.next(response.data as EntityType);
            return true;
          } else {
            return false;
          }
        })
      );
  }

  create(data: EntityType): Observable<EntityType | undefined | EntityType[]> {
    return this.http
      .post<ApiResponse<EntityType>>(
        this.configService.getApiUrl() + this.getServiceEndpoint() + '/',
        data
      )
      .pipe(
        map((response) => {
          if (response.status >= 200 && response.data) {
            console.log('emitting');
            this.entryCreatedSubject.next(response.data as EntityType);
            return response.data;
          } else {
            return undefined;
          }
        })
      );
  }

  update(id: number, data: EntityType): Observable<boolean> {
    return this.http
      .put<ApiResponse<EntityType>>(
        this.configService.getApiUrl() + this.getServiceEndpoint() + '/' + id,
        data
      )
      .pipe(
        map((response) => {
          if (response.status >= 200) {
            this.entryUpdatedSubject.next(response.data as EntityType);
            return true;
          } else {
            return false;
          }
        })
      );
  }

  protected abstract getServiceEndpoint(): string;
  public abstract getColumnDefs(): IColumnDef[];

  public getGridConfig() {
    const gridConfig = new GridConfig<EntityType>();
    gridConfig.columnDefs = this.getColumnDefs();
    gridConfig.displayedColumns = this.getDisplayedColumns();
    return gridConfig;
  }

  public getDisplayedColumns(): string[] {
    return this.getColumnDefs()
      .filter((def) => def.inGrid)
      .map((def) => def.dataKey);
  }

  postProcessGetAll(items: EntityType[]): EntityType[] {
    return items;
  }

  getAll(): Observable<EntityType[]> {
    return this.http
      .get<ApiResponse<EntityType>>(
        this.configService.getApiUrl() + this.getServiceEndpoint() + '/'
      )
      .pipe(
        map((response) => {
          if (Array.isArray(response.data)) {
            return this.postProcessGetAll(response.data!);
          } else {
            return [];
          }
        })
      );
  }
  getById(id: number): Observable<EntityType | undefined> {
    return this.http
      .get<ApiResponse<EntityType>>(
        this.configService.getApiUrl() + this.getServiceEndpoint() + '/' + id
      )
      .pipe(
        map((response) => {
          if (!Array.isArray(response.data)) {
            return this.postProcessGetAll([response.data!])[0];
            //return response.data!;
          } else {
            return undefined;
          }
        })
      );
  }

  getCustom(
    customPath: string
  ): Observable<EntityType[] | EntityType | boolean | undefined> {
    return this.http
      .get<ApiResponse<EntityType>>(this.configService.getApiUrl() + customPath)
      .pipe(map((response) => response.data));
  }

  getCustomTyped<T>(customPath: string): Observable<T | T[] | undefined> {
    return this.http
      .get<ApiResponse<T>>(this.configService.getApiUrl() + customPath)
      .pipe(map((response) => response.data));
  }

  postCustom<T>(customPath: string, postData: unknown): Observable<T> {
    return this.http
      .post<ApiResponse<T>>(
        this.configService.getApiUrl() + customPath,
        postData
      )
      .pipe(map((response) => response.data as T));
  }

  transformToDateString(columnValue: unknown) {
    if (!columnValue) {
      return '';
    }

    if (typeof columnValue === 'string') {
      return new Date(columnValue).toDateString().substring(4);
    }

    return new Date((columnValue as CustomDate).date)
      .toDateString()
      .substring(4);
  }

  transformToActualDate(columnValue: unknown) {
    if (!columnValue) {
      return '';
    }

    if (typeof columnValue === 'string') {
      return columnValue;
    }

    return (columnValue as CustomDate).date;
  }
}
