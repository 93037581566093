<div class="content">
  <div class="login-form">
    <form [formGroup]="loginForm">
      <div class="subtitle-text">Kochikachar Bornomala</div>
      <mat-form-field appearance="outline">
        <input
          matInput
          placeholder="Username"
          required
          formControlName="username"
        />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <input
          matInput
          [type]="passwordHide ? 'password' : 'text'"
          placeholder="Password"
          required
          formControlName="password"
        />
        <button
          mat-icon-button
          matSuffix
          (click)="passwordHide = !passwordHide"
          [attr.aria-label]="'Hide password'"
          [attr.aria-pressed]="passwordHide"
        >
          <mat-icon>{{
            passwordHide ? "visibility_off" : "visibility"
          }}</mat-icon>
        </button>
      </mat-form-field>

      <button
        mat-flat-button
        color="primary"
        class="login-btn"
        [disabled]="!loginForm.valid"
        (click)="onLogin()"
      >
        Login
      </button>

      <div><a (click)="onForgetClick()">Forgot Username / Password</a></div>
    </form>
  </div>
</div>
