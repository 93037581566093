import { Observable, map } from 'rxjs';
import { DataServiceBase } from './data-service-base.class';

export abstract class DependentDataServiceBase<
  EntityType
> extends DataServiceBase<EntityType> {
  protected abstract getDependentDataEndpoint(): string;
  protected abstract getRootDataEndpoint(): string;
  protected abstract postProcessDependentDataRead(entities: EntityType[]): void;

  public getDependentEntities(rootItemId: number): Observable<EntityType[]> {
    return this.getCustom(
      this.getDependentDataEndpoint() + '/' + rootItemId
    ).pipe(
      map((result) => {
        if (Array.isArray(result)) {
          this.postProcessDependentDataRead(result);
          return result;
        } else {
          return [];
        }
      })
    );
  }

  public getRootEntities(dependentItemId: number): Observable<EntityType[]> {
    return this.getCustom(
      this.getRootDataEndpoint() + '/' + dependentItemId
    ).pipe(
      map((result) => {
        if (Array.isArray(result)) {
          this.postProcessDependentDataRead(result);
          return result;
        } else {
          return [];
        }
      })
    );
  }
}
