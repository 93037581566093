import { Injectable } from '@angular/core';
import { ILookupService } from 'src/app/core/models/data-service/lookup-service.interface';
import { IClassGrade } from 'src/app/core/models/lookup-types/class-grade.interface';

@Injectable({
  providedIn: 'root',
})
export class ClassGradeLookupService implements ILookupService<IClassGrade> {
  private classGrades: IClassGrade[] = [
    { id: 0, displayName: 'Pre-School' },
    { id: 1, displayName: 'Class 1' },
    { id: 2, displayName: 'Class 2' },
    { id: 3, displayName: 'Class 3' },
    { id: 4, displayName: 'Class 4' },
    { id: 5, displayName: 'Class 5' },
  ];

  loadItems(): void {}
  getAllItems(): IClassGrade[] {
    return this.classGrades;
  }
  getItemById(id: number): IClassGrade | undefined {
    return this.classGrades.find((grade) => grade.id === id);
  }
  getValue(key: string | number): string | number {
    return this.getItemById(key as number)?.displayName ?? '';
  }
  getDisplayName(data: IClassGrade): string {
    return data.displayName;
  }
}
