import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../../services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { ResetCredential } from 'src/app/core/models/reset-credential.interface';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  public checking: boolean = true;
  public valid: boolean = false;
  public showForm: boolean = false;
  public message: string = '';
  private resetToken: string | null = '';

  public resetPasswordForm = this.formBuilder.group(
    {
      logonName: ['', Validators.required],
      password: ['', Validators.required],
      repeatPassword: ['', [Validators.required]],
    },
    {
      validator: this.passwordMatchValidator,
    }
  );

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  passwordMatchValidator(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    const password = control.get('password');
    const repeatPassword = control.get('repeatPassword');

    if (password?.value !== repeatPassword?.value) {
      return { passwordMismatch: true };
    }

    return null;
  }

  ngOnInit(): void {
    this.resetToken = this.activatedRoute.snapshot.paramMap.get('resetToken');
    if (this.resetToken !== null) {
      this.authService.isResetTokenValid(this.resetToken).subscribe({
        next: (valid) => {
          this.checking = false;
          this.valid = !!valid;

          if (!valid) {
            this.message = 'দুঃখিত। লিঙ্কটি এখন আর সক্রিয় নয়। ';
          } else {
            this.showForm = true;
          }
        },
        error: (error) => {
          this.checking = false;
          this.valid = false;
          this.message =
            'দুঃখিত। সার্ভার এর সাথে যোগাযোগ করা সম্ভব হচ্ছে না। দয়া করে একটু পর আবার চেষ্টা করুন।';
        },
      });
    }
  }

  public onResetPassword() {
    this.checking = true;
    const formData = this.resetPasswordForm.value;
    if (
      formData.logonName === '' ||
      formData.password === '' ||
      formData.repeatPassword === '' ||
      formData.password !== formData.repeatPassword
    ) {
      return;
    }

    const resetData: ResetCredential = {
      logonName: formData.logonName,
      password: window.btoa(formData.password),
      passwordRecoveryToken: this.resetToken || '',
    };

    this.authService.resetPassword(resetData).subscribe({
      next: (result) => {
        this.showForm = false;
        this.checking = false;
        this.message = result
          ? 'নতুন ইউজারনেম ও পাসওয়ার্ড নিবন্ধন সফল হয়েছে।'
          : 'দুঃখিত। দয়া করে আপনার দেওয়া তথ্য যাচাই করুন এবং আবার চেষ্টা করুন।';
      },
      error: () => {
        this.showForm = false;
        this.checking = false;
        this.message =
          'দুঃখিত। সার্ভার এর সাথে যোগাযোগ করা সম্ভব হচ্ছে না। দয়া করে একটু পর আবার চেষ্টা করুন।';
      },
    });
  }

  public onLoginClick() {
    this.router.navigate(['login']);
  }
}
