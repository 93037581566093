import { Injectable } from '@angular/core';
import { DataServiceBase } from 'src/app/core/data-service-base.class';
import { IColumnDef } from 'src/app/core/models/data-service/column-def.interface';
import { Permission } from 'src/app/core/models/table-entities/permission.interface';
import { ConfigService } from '../config.service';
import { HttpClient } from '@angular/common/http';
import { ILookupService } from 'src/app/core/models/data-service/lookup-service.interface';
import { LookupDataServiceBase } from 'src/app/core/lookup-data-service-base.class';

@Injectable({
  providedIn: 'root',
})
export class PermissionService extends LookupDataServiceBase<Permission> {
  protected override getServiceEndpoint(): string {
    return 'permission';
  }
  public override getColumnDefs(): IColumnDef[] {
    return [];
  }

  constructor(configService: ConfigService, http: HttpClient) {
    super(configService, http);
    this.loadItems();
  }
  getDisplayName(data: Permission): string {
    return data.permissionDescription;
  }
}
