import { Injectable, inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable, map, take } from 'rxjs';
import { LoginData } from 'src/app/core/models/login-data.interface';
import { LoginResult } from 'src/app/core/models/login-result.interface';
import { Onboarding } from 'src/app/core/models/onboarding.interface';
import { ResetCredential } from 'src/app/core/models/reset-credential.interface';
import { ConfigService } from 'src/app/services/config.service';
import { ApiService } from './api.service';
import { useAnimation } from '@angular/animations';

export const AuthGuard: CanActivateFn = (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const router = inject(Router);
  return inject(AuthService)
    .isLoggedOn()
    .pipe(
      take(1),
      map((authenticated) => {
        if (!authenticated) {
          return router.createUrlTree(['/login']);
        }
        return true;
      })
    );
};

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  performLogout(userId: number) {
    localStorage.removeItem(this.getStorageKey(userId));
  }
  isLoggedOn(): Observable<boolean> {
    return this.apiService.getRequest<boolean>('is_logged_on');
  }
  isResetTokenValid(resetToken: string) {
    return this.apiService.getRequest(
      'is_password_reset_token_valid/' + resetToken
    );
  }

  isLogonNameAvailable(logonName: string) {
    return this.apiService.getRequest<boolean>(
      'is_username_available/' + logonName
    );
  }

  saveAuthToken(token: string, userId: number) {
    // save token to storage
    console.log('[AuthService] saving auth token to storage', window.location);
    localStorage.setItem(this.getStorageKey(userId), token);
  }

  public getAuthToken(userId: number) {
    return localStorage.getItem(this.getStorageKey(userId));
  }

  performLogin(loginData: LoginData): Observable<LoginResult> {
    console.log('[AuthService] performing login');
    return this.apiService.postRequestWithCredentials<LoginResult>(
      'authenticate',
      loginData
    );
  }
  completeOnboarding(onboardingData: Onboarding) {
    return this.apiService.postRequest('complete_onboarding', onboardingData);
  }
  constructor(private apiService: ApiService) {}

  public isInvitationCodeValid(invitationCode: string) {
    return this.apiService.getRequest('validate_invite_code/' + invitationCode);
  }

  public isEmailConfirmationTokenValid(confirmationToken: string) {
    return this.apiService.getRequest(
      'verify_email_change/' + confirmationToken
    );
  }

  public requestResetPassword(email: string) {
    return this.apiService.getRequest<boolean>('forgot_password/' + email);
  }

  public resetPassword(resetData: ResetCredential) {
    return this.apiService.postRequest<boolean>('reset_password', resetData);
  }

  private getStorageKey(userId: number) {
    return window.location.origin + '/' + userId + '/auth';
  }
}
