<div class="content">
  <div class="reset-password-form">
    <form [formGroup]="recoverPasswordForm" *ngIf="showForm">
      <div class="subtitle-text">Kochikachar Bornomala</div>
      <mat-form-field appearance="outline">
        <input
          matInput
          placeholder="Email"
          required
          formControlName="email"
          [disabled]="controlDisabled"
        />
      </mat-form-field>

      <button
        mat-flat-button
        color="primary"
        class="reset-password-btn"
        [disabled]="!recoverPasswordForm.valid || controlDisabled"
        (click)="onRecoverPassword()"
      >
        Reset Password
      </button>

      <div><a (click)="onBackToLoginClock()">Back to Login</a></div>
    </form>
    <div *ngIf="!showForm">
      পাসওয়ার্ড পুনরায় নিবন্ধনের জন্যে একটি লিঙ্ক আপনাকে ইমেল করা হয়েছে। দয়া
      করে লিঙ্ক এ ক্লিক করুন এবং আপনার পাসওয়ার্ড টি পুনরায় নিবন্ধন করুন।
    </div>
  </div>
</div>
