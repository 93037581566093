import { Injectable, inject } from '@angular/core';
import { Observable, map, of, switchMap } from 'rxjs';
import { UserPermission } from '../core/models/user-permission.interface';
import { PermissionType } from '../core/constants/permissions.enum';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  RouterStateSnapshot,
} from '@angular/router';
import { ApiService } from './api.service';

export const PermissionGuard: CanActivateFn = (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  return inject(PermissionManager).canActivate(next, state);
};

@Injectable({
  providedIn: 'root',
})
export class PermissionManager {
  userPermissions: UserPermission[] = [
    {
      permission: PermissionType.ManageUsers,
      permitted: true,
    },
    {
      permission: PermissionType.ManageAdmissionRequest,
      permitted: true,
    },
    {
      permission: PermissionType.ManageAdmission,
      permitted: true,
    },
    {
      permission: PermissionType.ManageStudents,
      permitted: true,
    },
    {
      permission: PermissionType.ManagePublicFeedback,
      permitted: true,
    },
    {
      permission: PermissionType.ManageSchool,
      permitted: true,
    },
    {
      permission: PermissionType.ManageClass,
      permitted: true,
    },
    {
      permission: PermissionType.ManageTeacherAssignment,
      permitted: true,
    },
    {
      permission: PermissionType.ManageSession,
      permitted: true,
    },
    {
      permission: PermissionType.SendGeneralNotice,
      permitted: true,
    },
    {
      permission: PermissionType.SendClassNotice,
      permitted: true,
    },
    {
      permission: PermissionType.SendGroupNotice,
      permitted: true,
    },
  ];

  private permissionMap = new Map<PermissionType, boolean>();
  private permissionStore = new Map<number, Map<PermissionType, boolean>>();

  loadPermissions(): Observable<boolean> {
    console.log('[PermissionManager] Loading permissions');
    this.permissionMap = new Map<PermissionType, boolean>();
    this.userPermissions.forEach((permission) => {
      this.permissionMap.set(permission.permission, permission.permitted);
    });
    return of(true); // dummy. load all permissions and complete
  }

  loadPermissionsOfUser(
    userId: number,
    isActiveUser: boolean
  ): Observable<boolean> {
    console.log('[PermissionManager] Loading permissions of user');

    return this.apiService
      .getRequestWithCredentials<number[]>('permissions_of_user/' + userId)
      .pipe(
        map((results) => {
          console.log('[PermissionManager]', results);
          const permissionMap = new Map<PermissionType, boolean>();
          this.userPermissions.forEach((permission) => {
            permissionMap.set(
              permission.permission,
              permission.permitted && results.includes(permission.permission)
            );
          });
          this.permissionStore.set(userId, permissionMap);
          if (isActiveUser) {
            this.permissionMap = permissionMap;
          }
          console.log('[PermissionManager]', this.permissionMap);
          return true;
        })
      );
  }

  public hasPermission(permission: PermissionType) {
    return this.permissionMap.get(permission) ?? false;
  }

  public userHasPermission(userId: number, permission: PermissionType) {
    if (!this.permissionStore.has(userId)) {
      return this.loadPermissionsOfUser(userId, false).pipe(
        map((loaded) => {
          return this.permissionStore.get(userId)?.get(permission) ?? false;
        })
      );
    } else {
      return of(this.permissionStore.get(userId)?.get(permission) ?? false);
    }
  }

  public canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    let hasRequiredPermissions = true;
    if (next.data && next.data['permissionsAll']) {
      next.data['permissionsAll'].forEach((permission: PermissionType) => {
        hasRequiredPermissions =
          hasRequiredPermissions && this.hasPermission(permission);
      });

      if (!hasRequiredPermissions) {
        return false;
      }
    }

    let hasAnyPermission = true;
    if (next.data && next.data['permissionsAny']) {
      hasAnyPermission = false;
      next.data['permissionsAny'].forEach((permission: PermissionType) => {
        hasAnyPermission = hasAnyPermission || this.hasPermission(permission);
      });

      if (hasAnyPermission) {
        return true;
      }
    }

    return true;
  }

  constructor(private apiService: ApiService) {}
}
