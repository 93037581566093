import { Injectable, inject } from '@angular/core';
import { StudentToClassService } from './student-to-class.service';
import { RelationshipService } from './relationship.service';
import { Observable, forkJoin, map, of, switchMap } from 'rxjs';
import { UserAccountService } from './user-account.service';
import { ClassGuardian } from 'src/app/core/models/class-guardians.interface';
import { GridConfig } from 'src/app/core/grid-config.class';
import { IColumnDef } from 'src/app/core/models/data-service/column-def.interface';
import { RowActionButton } from 'src/app/core/models/ui/row-action-button.interface';
import { PermissionManager } from '../permission-manager.service';
import { Student } from 'src/app/core/models/table-entities/student.interface';
import { StudentToClass } from 'src/app/core/models/table-entities/student-to-class.interface';
import { Relationship } from 'src/app/core/models/table-entities/relationship.interface';

@Injectable({
  providedIn: 'root',
})
export class GuardiansToClassService {
  getColumnDefs(): IColumnDef[] {
    return [
      {
        label: 'Name',
        dataKey: 'name',
        inGrid: true,
      },
      {
        label: 'Teacher Candidate',
        dataKey: 'teacherCandidate',
        inGrid: true,
        customComponent: 'checkbox',
      },
      {
        label: 'Is Teacher',
        dataKey: 'isTeacher',
        inGrid: true,
        customComponent: 'checkbox',
      },
    ];
  }

  private studentsInClassService = inject(StudentToClassService);
  private relationshipService = inject(RelationshipService);
  private userAccountService = inject(UserAccountService);
  private permissionManager = inject(PermissionManager);

  private classGuardiansMap = new Map<number, ClassGuardian[]>();
  private tempStudents: StudentToClass[] = [];

  constructor() {}

  private loadGuardians() {
    this.studentsInClassService.getAll().pipe(
      switchMap((students) => {
        this.tempStudents = students;
        return this.relationshipService.getGuardiansOfStudents(students);
      }),
      map((relations) => {
        let processed = this.postProcessGuardians(relations);
        this.classGuardiansMap.clear();
        processed.forEach((guardian) => {
          guardian.children.forEach((child) => {
            let classOfChild = this.tempStudents.find((s) => s.id === child.id);
            if (classOfChild) {
              if (!this.classGuardiansMap.has(classOfChild.id)) {
                this.classGuardiansMap.set(classOfChild.id, []);
              }
              this.classGuardiansMap.get(classOfChild.id)?.push(guardian);
            }
          });
        });
        return this.classGuardiansMap;
      })
    );
  }

  public getClassGuardiansMap() {
    if (this.classGuardiansMap.size > 0) {
      return of(this.classGuardiansMap);
    } else {
      return this.loadGuardians();
    }
  }

  public getGuardiansOfClass(classId: number) {
    return this.studentsInClassService.getDependentEntities(classId).pipe(
      switchMap((students) => {
        return this.relationshipService.getGuardiansOfStudents(students);
      }),
      map((relations) => {
        this.loadPermissionsOfRelations(relations);
        return this.postProcessGuardians(relations);
      })
    );
  }

  private postProcessGuardians(relations: Relationship[]) {
    const guardiansInClass: ClassGuardian[] = [];
    relations.forEach((relation) => {
      const existingGuardian = guardiansInClass.find(
        (guardian) => guardian.id === relation.guardianId
      );

      if (!existingGuardian) {
        guardiansInClass.push({
          id: relation.guardianId,
          name: relation.guardianName || '',
          teacherCandidate:
            this.userAccountService.getItemById(relation.guardianId)
              ?.isTeacher || false,
          isTeacher: false,
          children: [
            {
              id: relation.studentId,
              name: relation.studentName || '',
            },
          ],
        });
      } else {
        existingGuardian.children.push({
          id: relation.studentId,
          name: relation.studentName || '',
        });
      }
    });

    return guardiansInClass;
  }

  private loadPermissionsOfRelations(relations: Relationship[]) {
    const observables: Observable<boolean>[] = [];
    relations.forEach((relation) => {
      observables.push(
        this.permissionManager.loadPermissionsOfUser(relation.guardianId, false)
      );
    });

    return forkJoin(observables).pipe(
      map((results) => {
        return relations;
      })
    );
  }
}
