import { Injectable } from '@angular/core';
import { ILookupService } from 'src/app/core/models/data-service/lookup-service.interface';
import { IClassState } from 'src/app/core/models/lookup-types/class-state.interface';
import { ILectureDayTime } from 'src/app/core/models/lookup-types/lecture-day-time.interface';

@Injectable({
  providedIn: 'root',
})
export class LectureDayLookupService
  implements ILookupService<ILectureDayTime>
{
  getItemById(id: number | string): ILectureDayTime | undefined {
    return this.days.find((day) => day.id === id);
  }
  getValue(key: string | number): string | number {
    return this.getItemById(key)?.displayName ?? '';
  }
  getDisplayName(data: ILectureDayTime): string {
    return data.displayName;
  }
  private days: ILectureDayTime[] = [
    { id: 'Sunday', displayName: 'Sunday' },
    { id: 'Monday', displayName: 'Monday' },
    { id: 'Tuesday', displayName: 'Tuesday' },
    { id: 'Wednesday', displayName: 'Wednesday' },
    { id: 'Thursday', displayName: 'Thursday' },
    { id: 'Friday', displayName: 'Friday' },
    { id: 'Saturday', displayName: 'Saturday' },
  ];

  loadItems(): void {}
  getAllItems(): ILectureDayTime[] {
    return this.days;
  }
}
