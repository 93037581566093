import { Injectable } from '@angular/core';
import { ILookupService } from 'src/app/core/models/data-service/lookup-service.interface';
import { ILectureDayTime } from 'src/app/core/models/lookup-types/lecture-day-time.interface';

@Injectable({
  providedIn: 'root',
})
export class LectureTimeLookupService
  implements ILookupService<ILectureDayTime>
{
  private lectureTimes: ILectureDayTime[] = [];
  loadItems(): void {
    this.lectureTimes = [];
    for (let hour = 0; hour < 24; hour++) {
      const time = (hour < 10 ? '0' : '') + hour + ':';
      this.lectureTimes.push({
        id: time + '00',
        displayName: time + '00',
      });

      this.lectureTimes.push({
        id: time + '30',
        displayName: time + '30',
      });
    }
  }
  getAllItems(): ILectureDayTime[] {
    return this.lectureTimes;
  }
  getItemById(id: string | number): ILectureDayTime | undefined {
    return this.lectureTimes.find((l) => l.id == id);
  }
  getValue(key: string | number): string | number {
    return this.getItemById(key)?.displayName ?? '';
  }
  getDisplayName(data: ILectureDayTime): string {
    return data.displayName;
  }
}
