import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private webApiUrl: string = environment.apiBaseUrl;

  constructor(private http: HttpClient) {}

  public getRequest<ReturnType = unknown>(path: string) {
    return this.http.get<ReturnType>(this.webApiUrl + path);
  }

  public getRequestWithCredentials<ReturnType = unknown>(path: string) {
    return this.http.get<ReturnType>(this.webApiUrl + path, {
      withCredentials: true,
    });
  }

  public postRequest<ReturnType = unknown>(
    path: string,
    data: any
  ): Observable<ReturnType> {
    return this.http.post<ReturnType>(this.webApiUrl + path, data);
  }

  public postRequestWithCredentials<ReturnType = unknown>(
    path: string,
    data: any
  ): Observable<ReturnType> {
    return this.http.post<ReturnType>(this.webApiUrl + path, data, {
      withCredentials: true,
    });
  }
}
