import { EventEmitter, Injectable } from '@angular/core';
import { DependentDataServiceBase } from 'src/app/core/dependent-data-service-base.class';
import { IColumnDef } from 'src/app/core/models/data-service/column-def.interface';
import { TeacherToClass } from 'src/app/core/models/table-entities/teacher-to-class.interface';
import { ConfigService } from '../config.service';
import { HttpClient } from '@angular/common/http';
import { UserAccountService } from './user-account.service';
import { PermissionType } from 'src/app/core/constants/permissions.enum';
import { Observable, map, of } from 'rxjs';
import { ClassGuardian } from 'src/app/core/models/class-guardians.interface';
import { ClassService } from './class.service';
import { GuardiansToClassService } from './guardians-to-class.service';

@Injectable({
  providedIn: 'root',
})
export class TeacherToClassService extends DependentDataServiceBase<TeacherToClass> {
  public teacherCreated$ = new EventEmitter<TeacherToClass>();
  public teacherDeleted$ = new EventEmitter<TeacherToClass>();
  public teacherAssigned$ = new EventEmitter<ClassGuardian>();
  public teacherRevoked$ = new EventEmitter<ClassGuardian>();

  reloadTeacherList() {
    this.userAccountService
      .getUserIdsHavingPermission(PermissionType.ManageSession)
      .subscribe((ids) => {
        this.teacherIds = ids;
      });
  }
  protected override getDependentDataEndpoint(): string {
    return 'teachers_of_class';
  }
  protected override getRootDataEndpoint(): string {
    return 'classes_of_teacher';
  }
  protected override postProcessDependentDataRead(
    entities: TeacherToClass[]
  ): void {
    entities.forEach((entity) => {
      entity.teacherName = this.userAccountService
        .getValue(entity.teacherId)
        .toString();
      entity.className = this.classService.getValue(entity.classId).toString();
    });
    this.entityStore = entities;
    this.classTeacherIds = entities.map((entity) => entity.teacherId);
  }
  protected override getServiceEndpoint(): string {
    return 'teacher_to_class';
  }
  public override getColumnDefs(): IColumnDef[] {
    return [
      {
        label: 'Teacher',
        dataKey: 'teacherId',
        inGrid: false,
        lookup: this.userAccountService,
        lookupFilter: (items) => {
          return items.filter(
            (item) =>
              this.teacherIds.includes(item.id) &&
              !this.classTeacherIds.includes(item.id)
          );
        },
        editor: 'select',
      },
      {
        label: 'Name',
        dataKey: 'teacherName',
        inGrid: true,
      },
      {
        label: 'Class Name',
        dataKey: 'className',
        inGrid: false,
      },
    ];
  }

  teacherIds: number[] = [];
  classTeacherIds: number[] = [];
  entityStore: TeacherToClass[] = [];

  constructor(
    configservice: ConfigService,
    http: HttpClient,
    private userAccountService: UserAccountService,
    private classService: ClassService,
    private guardianToClassService: GuardiansToClassService
  ) {
    super(configservice, http);
    this.reloadTeacherList();
  }

  // addUserToClassTeacher(userId: number) {
  //   if (!this.classTeacherIds.includes(userId)) {
  //     this.classTeacherIds.push(userId);
  //   }
  // }

  // removeUserFromClassTeacher(userId: number) {
  //   if (this.classTeacherIds.includes(userId)) {
  //     this.classTeacherIds.splice(this.classTeacherIds.indexOf(userId), 1);
  //   }
  // }

  public getTeachersOfClass(classId: number): Observable<TeacherToClass[]> {
    return this.getDependentEntities(classId);
  }

  public getClassesOfTeacher(teacherId: number): Observable<TeacherToClass[]> {
    return this.getRootEntities(teacherId);
  }

  revokeTeacher(
    id: number,
    classId: number
  ): Observable<TeacherToClass | undefined> {
    const item = this.entityStore.find(
      (teacher) => teacher.teacherId === id && teacher.classId === classId
    );
    if (item) {
      return this.delete(item.id!).pipe(
        map((result) => {
          return item;
        })
      );
    } else {
      return of(undefined);
    }
  }
}
