import { Injectable } from '@angular/core';
import { ClassState } from 'src/app/core/constants/class-state.enum';
import { ILookupService } from 'src/app/core/models/data-service/lookup-service.interface';
import { IClassState } from 'src/app/core/models/lookup-types/class-state.interface';

@Injectable({
  providedIn: 'root',
})
export class ClassStateLookupService implements ILookupService<IClassState> {
  private states: IClassState[] = [
    { id: ClassState.New, displayName: 'New' },
    { id: ClassState.Running, displayName: 'Running' },
    { id: ClassState.Completed, displayName: 'Completed' },
  ];

  loadItems(): void {}
  getAllItems(): IClassState[] {
    return this.states;
  }
  getItemById(id: number): IClassState | undefined {
    return this.states.find((state) => state.id === id);
  }
  getValue(key: string | number): string | number {
    return this.getItemById(key as number)?.displayName ?? '';
  }
  getDisplayName(data: IClassState): string {
    return data.displayName;
  }
}
