import { Injectable, InjectionToken } from '@angular/core';
import { DataServiceBase } from 'src/app/core/data-service-base.class';
import { IColumnDef } from 'src/app/core/models/data-service/column-def.interface';
import { Student } from 'src/app/core/models/table-entities/student.interface';
import { ConfigService } from '../config.service';
import { HttpClient } from '@angular/common/http';
import { UserAccountService } from './user-account.service';
import { LookupDataServiceBase } from 'src/app/core/lookup-data-service-base.class';

export const STUDENT_SERVICE_TOKEN = new InjectionToken<
  DataServiceBase<Student>
>('StudentServiceToken');

@Injectable({
  providedIn: 'root',
})
export class StudentService extends LookupDataServiceBase<Student> {
  public override getDisplayName(data: Student): string {
    return data.name;
  }
  protected override getServiceEndpoint(): string {
    return 'student';
  }
  public override getColumnDefs(): IColumnDef[] {
    return [
      {
        label: 'Name',
        dataKey: 'name',
        inGrid: true,
        editor: 'description',
      },
      {
        label: 'Name Bangla',
        dataKey: 'nameBangla',
        inGrid: true,
        editor: 'description',
      },
      {
        label: 'Birthday',
        dataKey: 'dateOfBirth',
        inGrid: true,
        editor: 'date',
        transform: this.transformToDateString,
        required: true,
      },
      {
        label: 'Created At',
        dataKey: 'createdAt',
        inGrid: true,
        transform: this.transformToDateString,
      },
      {
        label: 'Created By',
        dataKey: 'createdBy',
        inGrid: false,
        lookup: this.userAccountService,
      },
      {
        label: 'Updated At',
        dataKey: 'updatedAt',
        inGrid: false,
        transform: this.transformToDateString,
      },
      { label: 'Updated By', dataKey: 'updatedBy', inGrid: false },
    ];
  }

  constructor(
    configservice: ConfigService,
    http: HttpClient,
    private userAccountService: UserAccountService
  ) {
    super(configservice, http);
  }
}
