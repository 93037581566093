import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './modules/auth/auth.module';
import { SharedModule } from './modules/shared/shared.module';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatMenuModule } from '@angular/material/menu';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { AuthInterceptorService } from './services/auth-interceptor.service';
import { AuthService } from './services/auth.service';
import { ConfigService } from './services/config.service';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { InfoDialogComponent } from './shared-components/info-dialog/info-dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { YesNoDialogComponent } from './shared-components/yes-no-dialog/yes-no-dialog.component';
import { ListSelectionDialogComponent } from './shared-components/list-selection-dialog/list-selection-dialog.component';
import { MatListModule } from '@angular/material/list';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ClassService } from './services/data-services/class.service';
import { UserAccountService } from './services/data-services/user-account.service';
import { StudentService } from './services/data-services/student.service';
import { RoleService } from './services/data-services/role.service';
import { PermissionService } from './services/data-services/permission.service';
import {
  catchError,
  forkJoin,
  lastValueFrom,
  of,
  switchMap,
  throwError,
} from 'rxjs';

// import {
//   USER_ACCOUNT_SERVICE_TOKEN,
//   UserAccountService,
// } from './services/data-services/user-account.service';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    InfoDialogComponent,
    YesNoDialogComponent,
    ListSelectionDialogComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AuthModule,
    CommonModule,
    SharedModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MatListModule,
    MatMenuModule,
    MatButtonModule,
    MatDialogModule,
    MatSnackBarModule,
    MatNativeDateModule,
    MatInputModule,
    MatFormFieldModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  exports: [
    CommonModule,
    FormsModule,
    MatMenuModule,
    MatButtonModule,
    MatListModule,
    MatFormFieldModule,
  ],
  providers: [
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: (service: ConfigService) => () => service.initializeApp(),
    //   deps: [ConfigService],
    //   multi: true,
    // },
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: (service: PermissionService) => () => service.loadItems(),
    //   deps: [PermissionService],
    //   multi: true,
    // },
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: (service: ClassService) => () => service.loadItems(),
    //   deps: [ClassService],
    //   multi: true,
    // },
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: (service: UserAccountService) => () => service.loadItems(),
    //   deps: [UserAccountService],
    //   multi: true,
    // },
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: (service: StudentService) => () => service.loadItems(),
    //   deps: [StudentService],
    //   multi: true,
    // },
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: (service: RoleService) => () => service.loadItems(),
    //   deps: [RoleService],
    //   multi: true,
    // },
    {
      provide: APP_INITIALIZER,
      useFactory:
        (
          configService: ConfigService,
          permissionService: PermissionService,
          classService: ClassService,
          userAccountService: UserAccountService,
          studentService: StudentService,
          roleService: RoleService
        ) =>
        () => {
          return lastValueFrom(
            configService.initializeApp().pipe(
              switchMap((initialized: boolean) => {
                if (initialized) {
                  return forkJoin([
                    permissionService.loadItems(),
                    classService.loadItems(),
                    userAccountService.loadItems(),
                    studentService.loadItems(),
                    roleService.loadItems(),
                  ]);
                } else {
                  return throwError('ConfigService initialization failed');
                }
              }),
              catchError((error) => {
                console.error(
                  '[APP_INITIALIZER] Error during initialization:',
                  error
                );
                return of(null); // Emitting null to indicate initialization failure
              })
            )
          );
        },
      deps: [
        ConfigService,
        PermissionService,
        ClassService,
        UserAccountService,
        StudentService,
        RoleService,
      ],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
      deps: [AuthService, ConfigService],
    },
    // {
    //   provide: USER_ACCOUNT_SERVICE_TOKEN,
    //   useClass: UserAccountService,
    // },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
