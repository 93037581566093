import { Injectable } from '@angular/core';
import { ConfigService } from '../config.service';
import { HttpClient } from '@angular/common/http';
import { DependentDataServiceBase } from 'src/app/core/dependent-data-service-base.class';
import { StudentToClass } from 'src/app/core/models/table-entities/student-to-class.interface';
import { IColumnDef } from 'src/app/core/models/data-service/column-def.interface';
import { StudentService } from './student.service';
import { Observable, map } from 'rxjs';
import { ClassService } from './class.service';

@Injectable({
  providedIn: 'root',
})
export class StudentToClassService extends DependentDataServiceBase<StudentToClass> {
  assignStudentsToClass(
    classId: number,
    studentIds: number[],
    oldClassIds: number[] = []
  ): Observable<StudentToClass[]> {
    const postData = {
      classId: classId,
      studentIds: studentIds,
      oldClassIds: oldClassIds,
    };

    return this.postCustom<StudentToClass[]>('assign_to_new_class', postData);
  }
  protected override getRootDataEndpoint(): string {
    return 'classes_of_student';
  }
  protected override getDependentDataEndpoint(): string {
    return 'students_of_class';
  }
  protected override postProcessDependentDataRead(
    entities: StudentToClass[]
  ): void {
    entities.forEach((student) => {
      student.studentName = this.studentService
        .getValue(student.studentId)
        .toString();
      student.className = this.classService
        .getValue(student.classId)
        .toString();
    });
  }
  protected override getServiceEndpoint(): string {
    return 'student_to_class';
  }
  public override getColumnDefs(): IColumnDef[] {
    return [
      {
        label: 'Student',
        dataKey: 'studentId',
        inGrid: false,
        lookup: this.studentService,
        editor: 'select',
      },
      {
        label: 'Name',
        dataKey: 'studentName',
        inGrid: true,
      },
    ];
  }

  public isStudentAssignedToNewClass(studentId: number, classIds: number[]) {
    const postData = {
      studentId: studentId,
      classIds: classIds,
    };
    return this.postCustom<StudentToClass>('assigned_new_class', postData).pipe(
      map((result: StudentToClass) => {
        if (result) {
          this.postProcessDependentDataRead([result]);
        }

        return result;
      })
    );
  }

  public getNewlyAssignedClasses(studentIds: number[], classIds: number[]) {
    const postData = {
      studentIds: studentIds,
      classIds: classIds,
    };
    return this.postCustom<StudentToClass[]>(
      'assigned_new_class_all',
      postData
    ).pipe(
      map((results: StudentToClass[]) => {
        this.postProcessDependentDataRead(results);

        return results;
      })
    );
  }

  constructor(
    configservice: ConfigService,
    http: HttpClient,
    private studentService: StudentService,
    private classService: ClassService
  ) {
    super(configservice, http);
  }
}
