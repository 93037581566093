import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../../../services/auth.service';

@Component({
  selector: 'app-confirm-email',
  templateUrl: './confirm-email.component.html',
  styleUrls: ['./confirm-email.component.scss'],
})
export class ConfirmEmailComponent implements OnInit {
  public checking: boolean = true;
  public valid: boolean = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService
  ) {}
  ngOnInit(): void {
    const confirmationToken =
      this.activatedRoute.snapshot.paramMap.get('confirmationToken');

    if (confirmationToken !== null) {
      this.authService
        .isEmailConfirmationTokenValid(confirmationToken)
        .subscribe({
          next: (valid) => {
            this.checking = false;
            this.valid = !!valid;
          },
          error: (error) => {
            this.checking = false;
            this.valid = false;
          },
        });
    } else {
      this.checking = false;
      this.valid = false;
    }
  }

  public goToLoginPage() {
    this.router.navigate(['login']);
  }
}
