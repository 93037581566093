import { Injectable, InjectionToken } from '@angular/core';
import { LookupDataServiceBase } from 'src/app/core/lookup-data-service-base.class';
import { IColumnDef } from 'src/app/core/models/data-service/column-def.interface';
import { UserAccount } from 'src/app/core/models/table-entities/user-account.interface';
import { ConfigService } from '../config.service';
import { HttpClient } from '@angular/common/http';
import { PermissionType } from 'src/app/core/constants/permissions.enum';
import { DataServiceBase } from 'src/app/core/data-service-base.class';
import { Observable, map, of } from 'rxjs';

export const USER_ACCOUNT_SERVICE_TOKEN = new InjectionToken<
  DataServiceBase<UserAccount>
>('UserAccountServiceToken');

@Injectable({
  providedIn: 'root',
})
export class UserAccountService extends LookupDataServiceBase<UserAccount> {
  constructor(configService: ConfigService, http: HttpClient) {
    super(configService, http);
  }

  public override getDisplayName(data: UserAccount): string {
    return data.name;
  }
  protected override getServiceEndpoint(): string {
    return 'user_account';
  }
  public override getColumnDefs(): IColumnDef[] {
    return [
      {
        label: 'Name',
        dataKey: 'name',
        inGrid: true,
        editor: 'description',
      },
      {
        label: 'Email',
        dataKey: 'email',
        inGrid: true,
        editor: 'email',
      },
      {
        label: 'Account Complete',
        dataKey: 'accountComplete',
        inGrid: true,
        customComponent: 'checkbox',
        transform: (value: boolean) => {
          return value ? 'Yes' : 'No';
        },
      },
      {
        label: 'Created At',
        dataKey: 'createdAt',
        inGrid: false,
        transform: this.transformToDateString,
      },
      {
        label: 'Created By',
        dataKey: 'createdBy',
        inGrid: false,
        lookup: this,
      },
      {
        label: 'Updated At',
        dataKey: 'updatedAt',
        inGrid: false,
        transform: this.transformToDateString,
      },
      { label: 'Updated By', dataKey: 'updatedBy', inGrid: false },
    ];
  }

  override postProcessGetAll(items: UserAccount[]): UserAccount[] {
    items.forEach((item) => {
      item.accountComplete =
        item.nameBangla !== undefined && !item['invitationCode'];
    });
    return items;
  }

  public getUserIdsHavingPermission(permission: PermissionType) {
    return this.http.get<number[]>(
      this.configService.getApiUrl() + 'users_with_permission/' + permission
    );
  }

  public sendInvitataionCode(userId: number) {
    const data = {
      userId: userId,
    };
    return this.postCustom<boolean>('send_invitation', data);
  }

  public requestPasswordReset(userId: number) {
    const data = {
      userId: userId,
    };
    return this.postCustom<boolean>('request_reset_password', data);
  }

  public requestEmailChange(email: string) {
    return this.getCustom('request_email_change/' + email);
  }

  public getEmailChangeInfo(userId: number): Observable<any> {
    return this.getCustomTyped<any>('email_change_info/' + userId);
  }
}
