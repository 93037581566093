import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './modules/auth/components/login/login.component';
import { RegisterComponent } from './modules/auth/components/register/register.component';
import { AdminLayoutComponent } from './modules/dashboard/layout/admin-layout/admin-layout.component';
import { OnboardingComponent } from './modules/auth/components/onboarding/onboarding.component';
import { ConfirmEmailComponent } from './modules/auth/components/confirm-email/confirm-email.component';
import { ForgotPasswordComponent } from './modules/auth/components/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './modules/auth/components/reset-password/reset-password.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'forgotPassword',
    component: ForgotPasswordComponent,
  },
  {
    path: 'resetPassword/:resetToken',
    component: ResetPasswordComponent,
  },
  {
    path: 'register',
    component: RegisterComponent,
  },
  {
    path: 'onboarding/:invitationCode',
    component: OnboardingComponent,
  },
  {
    path: 'confirmEmail/:confirmationToken',
    component: ConfirmEmailComponent,
  },
  {
    path: 'verify_email_change/:confirmationToken',
    component: ConfirmEmailComponent,
  },
  {
    path: 'admin',
    component: AdminLayoutComponent,
    loadChildren: () =>
      import('./modules/dashboard/dashboard.module').then(
        (m) => m.DashboardModule
      ),
  },
  {
    path: '',
    redirectTo: 'admin',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
