import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ICulture } from '../../../../core/models/culture.interface';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { ConfigService } from 'src/app/services/config.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private router: Router,
    private configService: ConfigService
  ) {}

  public headeTitle?: string;

  ngOnInit(): void {
    this.selectedCulture = this.cultures[0];
  }
  @Output() sideNavToggled = new EventEmitter<boolean>();
  @Input() sideNavOpen: boolean = true;
  @Input() isMobileQueryMatch: boolean = false;
  cultures: ICulture[] = [
    {
      culture: 'en',
      label: 'English',
    },
    {
      culture: 'bn',
      label: 'বাংলা',
    },
  ];
  selectedCulture?: ICulture;

  ToggleSideNav() {
    this.sideNavOpen = !this.sideNavOpen;
    this.sideNavToggled.emit(this.sideNavOpen);
    console.log(this.sideNavOpen);
  }

  selectCulture(cultureKey: string) {
    this.selectedCulture = this.cultures.find((culture) => {
      return culture.culture === cultureKey;
    });
  }

  goToAccountManagement() {
    this.router.navigate(['admin/account']);
  }

  performLogout() {
    this.authService.performLogout(this.configService.getCurrentUserId());
    this.router.navigate(['login']);
  }
}
