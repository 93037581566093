import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../../../services/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent {
  public controlDisabled = false;
  public showForm = true;
  private messageUnknownEmail =
    'দুঃখিত। আপনার দেওয়া ইমেল টি আমরা চিনতে পারছি না। দয়া করে সঠিক ইমেল দিন এবং আবার চেষ্টা করুন।';
  private messageServerDown =
    'দুঃখিত। বর্তমানে সার্ভার এর সাথে যোগাযোগ করা সম্ভব হচ্ছে না। দয়া করে একটু পর আবার চেষ্টা করুন।';

  public recoverPasswordForm = this.formBuilder.group({
    email: ['', [Validators.required, Validators.email]],
  });

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private snackBar: MatSnackBar
  ) {}

  public onRecoverPassword() {
    this.controlDisabled = true;
    const email = this.recoverPasswordForm.value.email;
    if (email) {
      this.authService.requestResetPassword(email).subscribe({
        next: (success) => {
          this.onRequestSuccess(success);
        },
        error: (error) => {
          this.onError(this.messageServerDown);
        },
      });
    }
  }

  private onRequestSuccess(success: boolean) {
    if (success) {
      this.showForm = false;
    } else {
      this.onError(this.messageUnknownEmail);
    }
  }

  private onError(message: string) {
    this.controlDisabled = false;
    this.recoverPasswordForm.reset();
    this.snackBar.open(message, 'X', {
      duration: 5000,
    });
  }

  public onBackToLoginClock() {
    this.router.navigate(['login']);
  }
}
