import { Injectable } from '@angular/core';
import { DependentDataServiceBase } from 'src/app/core/dependent-data-service-base.class';
import { Relationship } from 'src/app/core/models/table-entities/relationship.interface';
import { ConfigService } from '../config.service';
import { HttpClient } from '@angular/common/http';
import { IColumnDef } from 'src/app/core/models/data-service/column-def.interface';
import { UserAccountService } from './user-account.service';
import { StudentService } from './student.service';
import { StudentToClass } from 'src/app/core/models/table-entities/student-to-class.interface';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RelationshipService extends DependentDataServiceBase<Relationship> {
  protected override getDependentDataEndpoint(): string {
    return 'student_relations';
  }
  protected override getRootDataEndpoint(): string {
    return 'guardian_relations';
  }
  protected override postProcessDependentDataRead(
    entities: Relationship[]
  ): void {
    entities.forEach((entity) => {
      this.processRelation(entity);
    });
  }
  protected override getServiceEndpoint(): string {
    return 'relationship';
  }
  public override getColumnDefs(): IColumnDef[] {
    return [
      {
        label: 'Student Id',
        dataKey: 'studentId',
        inGrid: false,
        editor: 'select',
        lookup: this.studentService,
      },
      {
        label: 'Sudent Name',
        dataKey: 'studentName',
        inGrid: true,
      },
    ];
  }

  constructor(
    configService: ConfigService,
    http: HttpClient,
    private userAccountService: UserAccountService,
    private studentService: StudentService
  ) {
    super(configService, http);
  }

  getGuardiansOfStudents(students: StudentToClass[]) {
    return this.postCustom<Relationship[]>('guardians_of_students', {
      studentIds: students.map((s) => s.studentId),
    }).pipe(
      map((relations) => {
        relations.forEach((relation) => {
          this.processRelation(relation);
        });
        return relations;
      })
    );
  }

  getChildrenOfUser(userId: number) {
    return this.getCustom('guardian_relations/' + userId).pipe(
      map((relations) => {
        relations = relations as Relationship[];
        relations.forEach((relation) => {
          this.processRelation(relation);
        });
        return relations;
      })
    );
  }

  private processRelation(relation: Relationship) {
    relation.guardianName = this.userAccountService
      .getValue(relation.guardianId)
      .toString();
    relation.studentName = this.studentService
      .getValue(relation.studentId)
      .toString();
  }
}
