import { HttpClient } from '@angular/common/http';
import { ConfigService } from '../services/config.service';
import { DataServiceBase } from './data-service-base.class';
import { IColumnDef } from './models/data-service/column-def.interface';
import { ILookupService } from './models/data-service/lookup-service.interface';
import { Identifyable } from './models/table-entities/identifyable.interface';
import { map } from 'rxjs';

export abstract class LookupDataServiceBase<EntityType extends Identifyable>
  extends DataServiceBase<EntityType>
  implements ILookupService<EntityType>
{
  public abstract getDisplayName(data: EntityType): string;

  private loadedItems: EntityType[] = [];

  constructor(configService: ConfigService, http: HttpClient) {
    super(configService, http);
    this.loadItems().subscribe();
    this.entryCreated$.subscribe(() => {
      this.loadItems().subscribe();
    });
    this.entryDeleted$.subscribe(() => {
      this.loadItems().subscribe();
    });
    this.entryUpdated$.subscribe(() => {
      this.loadItems().subscribe();
    });
  }

  loadItems() {
    return this.getAll().pipe(
      map((result) => {
        console.log('Loading finished', result);
        this.loadedItems = result;
        return true;
      })
    );
  }
  getAllItems(): EntityType[] {
    return this.loadedItems;
  }
  getItemById(id: number): EntityType | undefined {
    return this.loadedItems.find((item) => item.id == id);
  }
  getValue(key: string | number): string | number {
    const item = this.getItemById(key as number);
    return item ? this.getDisplayName(item) : '';
  }
}
