export class UserContext {
  private _id: number;
  private email?: string;
  private assignedRoles?: number[];

  constructor() {
    this._id = 0;
  }

  public get id(): number {
    return this._id;
  }

  public set id(userId: number) {
    this._id = userId;
  }
}
