import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  NavigationEnd,
  Router,
  Event as NavigationEvent,
  RouterEvent,
  Scroll,
} from '@angular/router';
import { INavItem } from '../../../../core/models/ui/nav-item.interface';
import { filter } from 'rxjs';
import { PermissionManager } from 'src/app/services/permission-manager.service';

@Component({
  selector: 'app-sidenav-item',
  templateUrl: './sidenav-item.component.html',
  styleUrls: ['./sidenav-item.component.scss'],
})
export class SidenavItemComponent implements OnInit {
  @Input() item?: INavItem;

  @Output() navigationComplete = new EventEmitter();
  //@Output() setParentOpen = new EventEmitter<boolean>();
  isActive: boolean = false;

  constructor(
    private router: Router,
    private permissionManager: PermissionManager
  ) {
    router.events.subscribe((event: NavigationEvent) => {
      if (event instanceof Scroll) {
        this.isActive = this.isItemActive();
        if (this.item?.children && this.item?.children.length > 0) {
          let foundMatch = this.isCurrentPathPresentInChildren(
            this.item?.children
          );
          if (foundMatch) {
            this.item.isOpen = true;
          }
        }
      }
    });
  }

  isCurrentPathPresentInChildren(children: INavItem[]): boolean {
    let found = false;
    for (let i = 0; i < children.length; i++) {
      if (children[i].path && this.isPathMatch(children[i].path!)) {
        found = true;
        break;
      } else if (children[i].children) {
        found = this.isCurrentPathPresentInChildren(children[i].children!);
        if (found) {
          children[i].isOpen = true;
          break;
        }
      }
    }
    return found;
  }

  ngOnInit(): void {
    this.isActive = this.isItemActive();
  }

  isItemActive() {
    return '/' + this.item?.path === this.router.url;
  }

  isPathMatch(path: string) {
    return '/' + path === this.router.url;
  }

  onClick() {
    if (this.item?.children && this.item?.children?.length > 0) {
      // open close child div
      this.item.isOpen = !this.item?.isOpen;
    } else if (this.item?.path) {
      this.router.navigate([this.item?.path]).then(
        (nav) => {
          console.log('navigating ', nav);
          this.navigationComplete.emit();
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }

  shouldBeDisplayed() {
    if (this.item && !this.item.permission) {
      return true;
    }

    if (this.item && this.item.permission) {
      return this.permissionManager.hasPermission(this.item?.permission);
    }

    return false;
  }
}
