import { Injectable, InjectionToken } from '@angular/core';
import { DataServiceBase } from 'src/app/core/data-service-base.class';
import { IColumnDef } from 'src/app/core/models/data-service/column-def.interface';
import { Role } from 'src/app/core/models/table-entities/role.interface';
import { ConfigService } from '../config.service';
import { HttpClient } from '@angular/common/http';
import { UserAccountService } from './user-account.service';
import { LookupDataServiceBase } from 'src/app/core/lookup-data-service-base.class';

export const ROLE_SERVICE_TOKEN = new InjectionToken<DataServiceBase<Role>>(
  'RoleServiceToken'
);

@Injectable({
  providedIn: 'root',
})
export class RoleService extends LookupDataServiceBase<Role> {
  public override getDisplayName(data: Role): string {
    return data.roleDescription;
  }
  protected getServiceEndpoint(): string {
    return 'role';
  }
  public getColumnDefs(): IColumnDef[] {
    return [
      {
        label: 'Role Description',
        dataKey: 'roleDescription',
        inGrid: true,
        editor: 'description',
      },
      {
        label: 'Created At',
        dataKey: 'createdAt',
        inGrid: true,
        transform: this.transformToDateString,
      },
      {
        label: 'Created By',
        dataKey: 'createdBy',
        inGrid: true,
        lookup: this.userAccountService,
      },
      {
        label: 'Updated At',
        dataKey: 'updatedAt',
        inGrid: true,
        transform: this.transformToDateString,
      },
      { label: 'Updated By', dataKey: 'updatedBy', inGrid: true },
    ];
  }

  override postProcessGetAll(items: Role[]): Role[] {
    items.forEach((item) => {
      item.createdAt = this.transformToActualDate(item.createdAt);
      item.updatedAt = this.transformToActualDate(item.updatedAt);
    });

    return items;
  }

  constructor(
    configservice: ConfigService,
    http: HttpClient,
    private userAccountService: UserAccountService
  ) {
    super(configservice, http);
  }
}
