<div class="content">
  <div class="reset-password-form">
    <form [formGroup]="resetPasswordForm" *ngIf="showForm">
      <div class="subtitle-text">Kochikachar Bornomala</div>
      <div class="subtitle-text">
        আপনার নতুন ইউজারনেম এবং পাসওয়ার্ড এখানে লিখুন।
      </div>
      <mat-form-field appearance="outline">
        <input
          matInput
          placeholder="Username"
          required
          formControlName="logonName"
          [disabled]="checking"
        />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <input
          matInput
          type="password"
          placeholder="Password"
          required
          formControlName="password"
          [disabled]="checking"
        />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <input
          matInput
          type="password"
          placeholder="Repeat Password"
          required
          formControlName="repeatPassword"
          [disabled]="checking"
        />
      </mat-form-field>

      <button
        mat-flat-button
        color="primary"
        class="reset-password-btn"
        [disabled]="!resetPasswordForm.valid || checking"
        (click)="onResetPassword()"
      >
        Reset Password
      </button>
    </form>
    <div *ngIf="!showForm">
      {{ message }}
      <div><a (click)="onLoginClick()">Login</a></div>
    </div>
  </div>
</div>
