import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfigService } from 'src/app/services/config.service';
import { AuthService } from '../../../../services/auth.service';
import {
  AbstractControl,
  AsyncValidatorFn,
  FormBuilder,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Onboarding } from 'src/app/core/models/onboarding.interface';
import { Observable, catchError, map, of } from 'rxjs';

export function usernameAvailabilityValidator(
  authService: AuthService
): AsyncValidatorFn {
  return (control: AbstractControl): Observable<ValidationErrors | null> => {
    const username = control.value;

    // Adjust the API endpoint and payload as per your backend setup
    return authService.isLogonNameAvailable(username).pipe(
      map((response) => {
        if (response) {
          return null; // Username is available, no error
        } else {
          return { usernameTaken: true }; // Username is taken, validation error
        }
      }),
      catchError(() => of(null)) // Handle any errors from the HTTP request
    );
  };
}

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss'],
})
export class OnboardingComponent implements OnInit {
  public checking: boolean = true;
  public valid: boolean = false;
  public formSubmitted: boolean = false;
  public infoMessage: string = '';

  constructor(
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private router: Router
  ) {}

  public onboardingForm = this.formBuilder.group({
    nameEnglish: [
      '',
      [Validators.required, Validators.pattern('^[a-zA-Zs ]+$')],
    ],
    nameBangla: [
      '',
      [Validators.required, Validators.pattern('^[\u0980-\u09FFs ]+$')],
    ],
    //email: ['', [Validators.required, Validators.email]],
    username: [
      '',
      [Validators.required, Validators.minLength(3)],
      [usernameAvailabilityValidator(this.authService)],
    ],
    password: ['', Validators.required],
  });

  public formData = {
    nameEnglish: '',
    nameBangla: '',
    //email: '',
    username: '',
    password: '',
  };

  private invitationCode: string | null = '';

  ngOnInit(): void {
    this.invitationCode =
      this.activatedRoute.snapshot.paramMap.get('invitationCode');
    if (this.invitationCode !== null) {
      this.authService.isInvitationCodeValid(this.invitationCode).subscribe({
        next: (valid) => {
          this.checking = false;
          this.valid = !!valid;
        },
        error: () => {
          this.checking = false;
          this.valid = false;
        },
      });
    } else {
      this.checking = false;
      this.valid = false;
    }
  }

  public goToLoginPage() {
    this.router.navigate(['login']);
  }

  onSubmit() {
    const formData = this.onboardingForm.value;
    const onboardingData: Onboarding = {
      invitationCode: this.invitationCode || '',
      nameEnglish: formData.nameEnglish!,
      nameBangla: formData.nameBangla!,
      //email: formData.email!,
      logonName: formData.username!,
      password: window.btoa(formData.password!),
    };

    this.checking = true;
    this.formSubmitted = true;
    this.authService.completeOnboarding(onboardingData).subscribe({
      next: (success) => {
        this.checking = false;
        this.infoMessage =
          'আপনার একাউন্ট তৈরি সম্পন্ন হয়েছে। নিচের লিঙ্ক এ ক্লিক করুন এবং আপনার ইউজারনেম ও পাসওয়ার্ড দিয়ে লগইন করুন।';
      },
      error: () => {
        this.checking = false;
        this.infoMessage =
          'দুঃখিত। আপনার অনুরোধকৃত প্রক্রিয়া টি সম্পন্ন হয় নাই। দয়া করে আবার চেষ্টা করুন। প্রয়োজনে আমাদের সাথে যোগাযোগ করুন।';
      },
    });
  }
}
