<div
  class="nav-list-item"
  [ngClass]="{ 'nav-list-item-active': isActive }"
  *ngIf="shouldBeDisplayed()"
>
  <a class="d-flex" (click)="onClick()">
    <i class="nav-root-icon {{ item?.icon }}"></i>
    <div class="me-auto nav-label">
      {{ item?.label }}
    </div>
    <i
      class="fa-solid fa-angle-right"
      *ngIf="item?.children && !item?.isOpen"
    ></i>
    <i
      class="fa-solid fa-angle-down"
      *ngIf="item?.children && item?.isOpen"
    ></i>
  </a>
  <div class="nav-child-list" *ngIf="item?.children && item?.isOpen">
    <app-sidenav-item
      *ngFor="let child of item?.children"
      [item]="child"
      (navigationComplete)="navigationComplete.emit()"
    ></app-sidenav-item>
  </div>
</div>
