import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { INavItem } from '../../../../core/models/ui/nav-item.interface';
import { PermissionType } from 'src/app/core/constants/permissions.enum';
import { ClassCoordinatorService } from 'src/app/services/data-services/class-coordinator.service';
import { ClassService } from 'src/app/services/data-services/class.service';
import { forkJoin } from 'rxjs';
import { ConfigService } from 'src/app/services/config.service';
import { TeacherToClassService } from 'src/app/services/data-services/teacher-to-class.service';
import { Class } from 'src/app/core/models/table-entities/class.interface';
import { ClassCoordinator } from 'src/app/core/models/table-entities/class-coordinator.interface';
import { TeacherToClass } from 'src/app/core/models/table-entities/teacher-to-class.interface';
import { RelationshipService } from 'src/app/services/data-services/relationship.service';
import { Relationship } from 'src/app/core/models/table-entities/relationship.interface';
import { StudentService } from 'src/app/services/data-services/student.service';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent {
  @Input() sideNavOpen: boolean = true;

  @Output() navigationCompleted = new EventEmitter();

  navList: INavItem[] = [
    {
      label: 'Dashboard',
      icon: 'fa-solid fa-home',
      path: 'admin/dashboard',
    },
    {
      label: 'Administration',
      icon: 'fa-solid fa-toolbox',
      children: [
        {
          label: 'Users',
          icon: 'fa-solid fa-address-book',
          path: 'admin/users',
          permission: PermissionType.ManageUsers,
        },
        {
          label: 'Roles',
          icon: 'fa-solid fa-user-gear',
          path: 'admin/roles',
        },
        {
          label: 'Students',
          icon: 'fa-solid fa-user-graduate',
          path: 'admin/students',
          permission: PermissionType.ManageStudents,
        },
        {
          label: 'Classes',
          icon: 'fa-solid fa-users',
          path: 'admin/class-list',
          permission: PermissionType.ManageSchool,
        },
        {
          label: 'Notice',
          icon: 'fa-solid fa-envelope',
          path: 'admin/notice',
          permission: PermissionType.SendGeneralNotice,
        },
      ],
    },
    {
      label: 'Applications',
      icon: 'fa-solid fa-graduation-cap',
      permission: PermissionType.ManageAdmission,
      children: [
        {
          label: 'Assigned To Me',
          icon: 'fa-solid fa-file-lines',
          path: 'admin/assigned-applications',
          permission: PermissionType.ManageAdmission,
        },
        {
          label: 'Pending',
          icon: 'fa-solid fa-file-lines',
          path: 'admin/admission-request',
          permission: PermissionType.ManageAdmissionRequest,
        },
        {
          label: 'All Applications',
          icon: 'fa-solid fa-box-archive',
          path: 'admin/all-applications',
          permission: PermissionType.ManageAdmission,
        },
      ],
    },
    // {
    //   label: 'Public Website',
    //   icon: 'fa-solid fa-globe',
    //   children: [
    //     {
    //       label: 'Text Contents',
    //       icon: 'fa-solid fa-indent',
    //       path: 'admin/contents',
    //     },
    //     {
    //       label: 'Media Management',
    //       icon: 'fa-solid fa-photo-film',
    //       path: 'admin/public-media',
    //     },
    //     {
    //       label: 'Gallery',
    //       icon: 'fa-solid fa-table-cells-large',
    //       path: 'admin/public-gallery',
    //     },
    //     {
    //       label: 'Public Notice',
    //       icon: 'fa-solid fa-bullhorn',
    //       path: 'admin/public-notice',
    //     },
    //   ],
    // },
    // {
    //   label: 'Internal Message',
    //   icon: 'fa-solid fa-envelope',
    // },
  ];

  managedClass: INavItem[] = [];
  teachingClass: INavItem[] = [];

  private classCoordinatorService = inject(ClassCoordinatorService);
  private classService = inject(ClassService);
  private configService = inject(ConfigService);
  private studentService = inject(StudentService);
  private teacherToClassService = inject(TeacherToClassService);
  private relationshipService = inject(RelationshipService);

  constructor() {
    forkJoin([
      this.classCoordinatorService.getAll(),
      this.classService.getAll(),
      this.teacherToClassService.getAll(),
      this.relationshipService.getAll(),
      this.studentService.loadItems(),
    ]).subscribe(([coordinators, classes, teachers, relations]) => {
      console.log('loaded coordinator info', coordinators, classes, teachers);
      this.buildClassCoordinatorLinks(classes, coordinators);
      this.buildTeachingLinks(teachers);
      this.buildChildrenLinks(relations);
    });
  }

  private buildChildrenLinks(relations: Relationship[]) {
    const childrenIds = relations
      .filter((r) => r.guardianId == this.configService.getCurrentUserId())
      .map((r) => r.studentId);
    const childrentItems: INavItem[] = [];
    if (childrenIds.length > 0) {
      childrenIds.forEach((studentId) => {
        childrentItems.push({
          label: this.studentService.getValue(studentId).toString(),
          icon: 'fa-solid fa-child',
          path: 'admin/child-detail/' + studentId,
        });
      });
    }

    childrentItems.push({
      label: 'Add Child',
      icon: 'fa-solid fa-add',
      path: 'admin/add-child',
    });

    const childrenNavItem: INavItem = {
      label: 'My Children',
      icon: 'fa-solid fa-children',
      children: childrentItems,
    };
    this.navList.push(childrenNavItem);
  }

  private buildClassCoordinatorLinks(
    classes: Class[],
    coordinators: ClassCoordinator[]
  ) {
    this.managedClass = [];
    classes.forEach((batch) => {
      const coordinatorItem = coordinators.find((c) => c.classId === batch.id);
      if (
        coordinatorItem &&
        coordinatorItem.userId == this.configService.getCurrentUserId()
      ) {
        this.managedClass.push({
          label: batch.name,
          icon: 'fa-solid fa-people-roof',
          path: 'admin/class-coordination/' + batch.id,
          children: this.buildCoordinationChildLinks(batch.id),
          permission: PermissionType.ManageTeacherAssignment,
        });
      }
    });

    if (this.managedClass.length > 0) {
      const classCoordinationItem: INavItem = {
        label: 'Class Coordination',
        icon: 'fa-solid fa-clipboard-list',
        permission: PermissionType.ManageTeacherAssignment,
        children: this.managedClass,
      };
      this.navList.push(classCoordinationItem);
    }
  }

  private buildCoordinationChildLinks(batchId: number) {
    const coordinatorChildren: INavItem[] = [
      {
        label: 'Home',
        icon: 'fa-solid fa-house-laptop',
        permission: PermissionType.ManageTeacherAssignment,
        path: 'admin/coordination-home/' + batchId,
      },
      {
        label: 'Lectures',
        icon: 'fa-solid fa-person-chalkboard',
        permission: PermissionType.ManageTeacherAssignment,
        path: 'admin/lecture-coordination/' + batchId,
      },
      {
        label: 'Students',
        icon: 'fa-solid fa-children',
        permission: PermissionType.ManageTeacherAssignment,
        path: 'admin/student-coordination/' + batchId,
      },
      {
        label: 'Teachers',
        icon: 'fa-solid fa-chalkboard-user',
        permission: PermissionType.ManageTeacherAssignment,
        path: 'admin/teacher-coordination/' + batchId,
      },
      {
        label: 'Messages',
        icon: 'fa-solid fa-envelope',
        permission: PermissionType.ManageTeacherAssignment,
        path: 'admin/coordination-messages/' + batchId,
      },
    ];
    return coordinatorChildren;
  }

  private buildTeachingLinks(teachers: TeacherToClass[]) {
    this.teachingClass = [];

    const classIds = teachers
      .filter(
        (teacher) => teacher.teacherId == this.configService.getCurrentUserId()
      )
      .map((teacher) => teacher.classId);
    if (classIds.length > 0) {
      const requests = classIds.map((classId) =>
        this.classService.getById(classId)
      );
      forkJoin(requests).subscribe((results) => {
        results.forEach((result) => {
          if (result) {
            const teachingClassItem: INavItem = {
              label: result.name,
              icon: 'fa-solid fa-clipboard-list',
              permission: PermissionType.ManageSession,
              path: 'admin/teaching/' + result.id,
            };

            this.teachingClass.push(teachingClassItem);
          }
        });
      });
      const teachingItem: INavItem = {
        label: 'My Lectures',
        icon: 'fa-solid fa-clipboard-list',
        permission: PermissionType.ManageSession,
        children: this.teachingClass,
      };
      this.navList.push(teachingItem);
    }
  }

  getManagedClass() {}
}
