import { Injectable } from '@angular/core';
import { DependentDataServiceBase } from 'src/app/core/dependent-data-service-base.class';
import { IColumnDef } from 'src/app/core/models/data-service/column-def.interface';
import { ClassCoordinator } from 'src/app/core/models/table-entities/class-coordinator.interface';
import { ConfigService } from '../config.service';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ClassCoordinatorService extends DependentDataServiceBase<ClassCoordinator> {
  protected override getRootDataEndpoint(): string {
    return 'class_of_coorinator';
  }
  protected override getDependentDataEndpoint(): string {
    return 'coordinator_of_class';
  }
  protected override postProcessDependentDataRead(
    entities: ClassCoordinator[]
  ): void {}

  protected override getServiceEndpoint(): string {
    return 'class_coordinator';
  }
  public override getColumnDefs(): IColumnDef[] {
    return [];
  }

  constructor(configservice: ConfigService, http: HttpClient) {
    super(configservice, http);
  }

  public hasCoordinator(classId: number) {
    return this.getDependentEntities(classId).pipe(
      map((entities) => entities.length > 0)
    );
  }
}
