import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { LoginData } from 'src/app/core/models/login-data.interface';
import { AuthService } from '../../../../services/auth.service';
import { LoginResult } from 'src/app/core/models/login-result.interface';
import { PermissionManager } from 'src/app/services/permission-manager.service';
import { ConfigService } from 'src/app/services/config.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  public passwordHide = true;
  public isLoggedOn = false;
  public logonCheckRunning = false;
  public message: string = '';

  public loginForm = this.formBuilder.group({
    username: ['', Validators.required],
    password: ['', Validators.required],
  });

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private permissionManager: PermissionManager,
    private configService: ConfigService,
    private router: Router,
    private snackBar: MatSnackBar
  ) {}

  public onForgetClick() {
    this.router.navigate(['forgotPassword']);
  }

  public onLogin() {
    this.logonCheckRunning = true;
    const formData = this.loginForm.value;
    if (formData.username === '' || formData.password === '') {
      return;
    }
    const loginData: LoginData = {
      logonName: formData.username!,
      password: window.btoa(formData.password!),
    };

    this.authService
      .performLogin(loginData)
      .subscribe((loginResult: LoginResult) => {
        if (loginResult.success && loginResult.token && loginResult.userId) {
          this.configService.initializeContext(loginResult.userId);
          this.authService.saveAuthToken(loginResult.token, loginResult.userId);
          this.permissionManager
            .loadPermissionsOfUser(loginResult.userId, true)
            .subscribe(() => {
              this.isLoggedOn = true;
              this.router.navigate(['admin/dashboard']);
            });
        } else {
          this.isLoggedOn = false;
          this.logonCheckRunning = false;
          this.message =
            'দয়া করে আপনার ইউজারনেম এবং পাসওয়ার্ড সঠিক আছে কি না তা যাচাই করুন এবং পুনরায় চেষ্টা করুন';
          this.snackBar.open(this.message, 'X', {
            duration: 5000,
          });
        }
      });
  }
}
