<div class="content">
  <div class="title-text">কচিকাঁচার বর্ণমালা অনলাইন স্কুল এ আপনাকে স্বাগতম</div>
  <div *ngIf="!checking && valid">
    <div class="subtitle-text">ইমেল নিশ্চিত করার জন্য আপনাকে ধন্যবাদ</div>
    <div class="subtitle-text">
      নিচের লিঙ্ক এ ক্লিক করুন এবং আপনার ইউজারনেম ও পাসওয়ার্ড দিয়ে লগইন করুন।
    </div>
    <div class="login-btn-wrapper">
      <button mat-flat-button color="primary" (click)="goToLoginPage()">
        লগইন করুন
      </button>
    </div>
  </div>

  <div *ngIf="!checking && !valid">
    <div class="subtitle-text">
      দুঃখিত। আমরা আপনার অনুরোধ শনাক্ত করতে অপারগ।
    </div>
  </div>

  <div *ngIf="checking" class="loading-overlay">
    <mat-spinner></mat-spinner>
  </div>
</div>
