<mat-toolbar>
  <button mat-icon-button (click)="ToggleSideNav()">
    <mat-icon>menu</mat-icon>
  </button>
  <span
    ><a href="#">{{ headeTitle }}</a></span
  >
  <div class="toolbar-spacer"></div>
  <!-- <button mat-button [matMenuTriggerFor]="menu_language">
    <mat-icon>translate</mat-icon>
    {{ selectedCulture?.label }}
  </button>
  <mat-menu #menu_language="matMenu">
    <button mat-menu-item (click)="selectCulture('bn')">বাংলা</button>
    <button mat-menu-item (click)="selectCulture('en')">English</button>
  </mat-menu> -->
  <button mat-icon-button [matMenuTriggerFor]="menu_accounts">
    <mat-icon>person</mat-icon>
  </button>
  <mat-menu #menu_accounts="matMenu">
    <button mat-menu-item (click)="goToAccountManagement()">Account</button>
    <button mat-menu-item (click)="performLogout()">Logout</button>
  </mat-menu>
</mat-toolbar>
