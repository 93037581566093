<div class="example-container" [class.example-is-mobile]="mobileQuery.matches">
  <app-header
    [sideNavOpen]="sideNavOpen"
    (sideNavToggled)="sideNavOpen = $event"
  ></app-header>

  <mat-sidenav-container
    class="example-sidenav-container"
    (backdropClick)="sideNavOpen = false"
  >
    <mat-sidenav
      #snav
      [opened]="sideNavOpen"
      [mode]="mobileQuery.matches ? 'over' : 'side'"
      [fixedInViewport]="mobileQuery.matches"
      fixedTopGap="56"
    >
      <app-sidenav
        [sideNavOpen]="sideNavOpen"
        [ngClass]="{ 'app-sidenav-open': sideNavOpen }"
        (navigationCompleted)="mobileQuery.matches && (sideNavOpen = false)"
      ></app-sidenav>
    </mat-sidenav>

    <mat-sidenav-content>
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
