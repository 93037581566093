<div class="content">
  <div class="title-text">কচিকাঁচার বর্ণমালা অনলাইন স্কুল এ আপনাকে স্বাগতম</div>

  <div *ngIf="checking" class="loading-overlay">
    <mat-spinner></mat-spinner>
  </div>

  <div *ngIf="!checking && !formSubmitted && valid">
    <div class="subtitle-text">
      আপনার একাউন্ট সম্পন্ন করার জন্যে নিচের ফর্মটি পূরণ করুন।
    </div>
    <div class="onboarding-form">
      <form [formGroup]="onboardingForm">
        <mat-form-field appearance="outline">
          <mat-label>Your Name (English)</mat-label>
          <input matInput formControlName="nameEnglish" />
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>আপনার নাম (বাংলা)</mat-label>
          <input matInput formControlName="nameBangla" />
        </mat-form-field>

        <!-- <mat-form-field appearance="outline">
          <mat-label>ইমেল এড্রেস</mat-label>
          <input matInput formControlName="email" />
        </mat-form-field> -->

        <mat-form-field appearance="outline">
          <mat-label>লগইন ইউজার</mat-label>
          <input matInput formControlName="username" />
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>লগইন পাসওয়ার্ড</mat-label>
          <input matInput type="password" formControlName="password" />
        </mat-form-field>

        <button
          mat-flat-button
          color="primary"
          (click)="onSubmit()"
          [disabled]="!onboardingForm.valid"
        >
          সাবমিট করুন
        </button>
      </form>
    </div>
  </div>

  <div *ngIf="!checking && !valid && !formSubmitted">
    দুঃখিত। আমরা আপনার অনুরোধ শনাক্ত করতে অপারগ।
  </div>

  <div *ngIf="!checking && formSubmitted">
    <div class="subtitle-text">প্রয়োজনীও তথ্য দেবার জন্য আপনাকে ধন্যবাদ</div>
    <div class="subtitle-text">
      {{ infoMessage }}
    </div>
    <div class="login-btn-wrapper">
      <button mat-flat-button color="primary" (click)="goToLoginPage()">
        লগইন করুন
      </button>
    </div>
  </div>

  <app-auth-footer></app-auth-footer>
</div>
