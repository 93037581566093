import { Injectable } from '@angular/core';
import {} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserContext } from '../core/user-context.class';
import { environment } from 'src/environments/environment';
import {
  Observable,
  delay,
  filter,
  forkJoin,
  map,
  of,
  switchMap,
  take,
} from 'rxjs';
import { PermissionManager } from './permission-manager.service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  initializeApp() {
    console.log('[ConfigService] app being initialized');
    console.log('[ConfigService] Saved context ', this.userContext);
    return this.authService.isLoggedOn().pipe(
      switchMap((loggedIn: boolean) => {
        if (loggedIn) {
          const loadPermissions$ = this.permissionManager.loadPermissionsOfUser(
            this.userContext.id,
            true
          );

          return loadPermissions$.pipe(
            switchMap(() => {
              console.log('[ConfigService] Initialization complete');
              return of(true);
            })
          );
        } else {
          return of(true);
        }
      })
    );
  }

  private webApiUrl: string = environment.apiBaseUrl;
  private userContext: UserContext = new UserContext();

  constructor(
    private http: HttpClient,
    private permissionManager: PermissionManager,
    private authService: AuthService
  ) {
    console.log('[ConfigService] cosntructor');
    const loggedInUserId = this.readData('userId');
    if (loggedInUserId) {
      this.userContext.id = Number.parseInt(loggedInUserId);
    }
  }

  public getApiUrl(): string {
    return this.webApiUrl;
  }

  public getCurrentUserId() {
    return this.userContext.id;
  }

  public initializeContext(userId: number) {
    this.userContext.id = userId;
    this.saveData('userId', userId);
  }

  private saveData(key: string, value: string | number) {
    const existingDataString = localStorage.getItem(this.getStorageKey());
    const existingData = existingDataString
      ? JSON.parse(existingDataString)
      : {};
    existingData[key] = value;
    localStorage.setItem(this.getStorageKey(), JSON.stringify(existingData));
  }

  private readData(key: string) {
    const existingDataString = localStorage.getItem(this.getStorageKey());
    const existingData = existingDataString
      ? JSON.parse(existingDataString)
      : {};
    return existingData[key];
  }

  private getStorageKey() {
    return window.location.origin + '/config';
  }
}
