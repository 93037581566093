export enum PermissionType {
  ManageUsers = 1,
  ManageAdmissionRequest = 2,
  ManageAdmission = 3,
  ManageStudents = 4,
  ManagePublicFeedback = 5,
  ManageSchool = 6,
  ManageClass = 7,
  ManageTeacherAssignment = 8,
  ManageSession = 9,
  SendGeneralNotice = 10,
  SendClassNotice = 11,
  SendGroupNotice = 12,
}
